/* @import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap');
* {
    box-sizing: border-box;
    /* font-family: 'M PLUS 1p', sans-serif; */
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}
p {margin-bottom: 5px !important;}
.mt10 {margin-top: 10px !important;}
.mt20 {margin-top: 20px !important;}
.mt30 {margin-top: 30px !important;}
.mt40 {margin-top: 40px !important;}
.mt50 {margin-top: 50px !important;}
.mb10 {margin-bottom: 10px !important;}
.mb20 {margin-bottom: 20px !important;}
.mb30 {margin-bottom: 30px !important;}
.mb40 {margin-bottom: 40px !important;}
.mb50 {margin-bottom: 50px !important;}

.navbar-toggler:focus {box-shadow: none !important;}
.outlinebtn-pink {border: 2px solid #ee3197 !important; border-radius: 5px; background: transparent; color: #ee3197; font-size: 14px; padding: 5px 10px; transition: all 0.3s;}
.announcementlist {margin:0; padding: 0;}
.announcementlist li {list-style:none; margin-top: 15px;}
.announcementlist li h3 {color:#4f2bdc; font-weight: 500 !important;}
.nopadleft {padding-left: 0 !important;}
.mt-50 {margin-top: -50px !important;}
.ml-2 {margin-left:10px}
.ml-3 {margin-left:20px}
.ml-5 {margin-left:50px}
.marginTop {margin-top: 150px !important;}
.padt10 {padding-top: 10px !important;}
.padt20 {padding-top: 20px !important;}
.padt30 {padding-top: 30px !important;}
.pad20 {padding: 20px;}
.pad30 {padding: 30px;}
.pad40 {padding: 40px;}
.pad50 {padding: 50px;}
.calendarContainer {width: 100% !important;}
#search_input {border: 0 !important;}
.plainbtn {border: 0; outline: 0; position: absolute; right: 5px; top: 10px; width: 40px; height: 25px; background: transparent; z-index: 5;}
.chip {background: #2a9bdb !important; font-size: 14px !important;}
.posrel {position: relative; z-index: 1;}
.shwpass {position: absolute; right: 25px; top: 40px; cursor: pointer; width: 25px; height: 25px; text-align: center; opacity: 0.5;}
.font-light {font-weight: 300 !important;}
.box-shadow {box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1) ;}
.text-right {text-align: right !important;}
.text-green {color: #03b92b !important;}
.green-text {color: #075117  !important;}
.text-red {color: #c7123f !important;}
.thumb {width: 50px; height: 50px; background-color: #ee3197; border-radius: 100px; display: flex; align-items: center; justify-content: center; font-weight: 700; color: #fff;}
.mr20 {margin-right: 20px !important;}
h3 {font-size: 1.3rem !important; font-weight: 700 !important;}
h4 {font-size: 0.95rem !important;}
.text-secondary {color: #9a9a9a !important;}
.text-warning {color: #f88d6c !important; font-weight: 500;}
#root .text-primary {color: #023e62 !important; }
.padlr50 {padding-left: 50px !important; padding-right: 50px !important;}
.lightgreencircle {width: 80px; height: 80px; display: flex; justify-content: center; align-items: center; background-color: #eaf3e6; border-radius: 100px;}
.block {background: #fff; box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.2); border-radius: 5px; position: relative; padding: 25px; margin:10px 20px; height: 100%;}
.block:first-child {margin-left: 0 !important;}
.block:last-child {margin-right: 0 !important;}
.padlr20 {padding-left: 20px !important; padding-right: 20px !important;}
.inline-list-bullet{ display: flex; justify-content: center; align-items: center; border-top: 1px solid #9a9a9a; padding-top: 20px; margin:0; padding: 20px 0 0 0; }
.inline-list-bullet li {margin: 0px 20px; list-style:none ; position: relative; font-weight: 700; color: #9a9a9a; display: flex; align-items: center;}
.inline-list-bullet li::before {content: ''; position: absolute; left: -40px; top: 50%; width: 10px; height: 10px; border-radius: 10px; background-color: #d6d6d6;}
.noBullet::before {display: none;}
.inline-list-bullet .big {width: 100%; display: inline-block; font-size: 2rem; text-align: left; font-weight: 400; color: #000;}
.smallCircleProgress {width: 60px; height: 60px; border:8px solid #9a9a9a; border-radius: 100px; margin-right: 20px;}
.maxHeight250 {max-height: 250px; overflow-x: auto;}
h1 {font-size: 3.5rem !important;}
.navbar-toggler:focus {border:0; outline: 0; box-shadow: none;}
.progress {border-radius: 100px !important;}
.bg-query {background-color: #ef476f !important;}
.bg-audit {background-color: #118ab2 !important;}
.bg-finalised {background-color: #aacc01 !important;}
.bg-query2 {background-color: #ffd166 !important;}
.bg-info {background-color: #ed8261 !important;}
.green-btn {background-color: #e8f3e4; border: 0;padding: 10px 20px; border-radius: 5px; color: #6eb053; font-weight: 500; transition: all 0.3s;}
.green-btn:hover {background-color: #6eb053; color: #ffff;}
.borderTop {border-top: 1px solid #9a9a9a;}
.bg-white-shadow {background: #fff; width: 100%; min-width: 300px; max-width: 330px; border-radius: 5px; box-shadow: 0px 0px 15px 3px rgb(0 0 0 / 10%); margin: 20px; padding: 20px;}
.lblue {background-color: #eaf5fb !important; color: #42a6df !important;}
.lblue svg {fill: #42a6df !important;}
.lblue button {border:2px solid #42a6df !important; border-radius: 5px; background: transparent; color:#42a6df; font-size:14px; padding: 5px 10px; transition: all 0.3s}
.lblue button:hover {background: #42a6df; color:#fff}
.lpink {background-color: #fdeaf5 !important; color: #ee3197 !important;}
.lpink svg {fill: #ee3197 !important;}
.lpink button {border:2px solid #ee3197 !important; border-radius: 5px; background: transparent; color:#ee3197; font-size:14px; padding: 5px 10px; transition: all 0.3s}
.lpink button:hover {background: #ee3197; color:#fff}
#root .btn-primary {color: #fff; background-color: #2a9bdb; border-color: #2a9bdb;}
.lbrown {background-color: #fbeded !important; color: #d94f46 !important;}
.lbrown svg {fill: #d94f46 !important;}
.lbrown button {border:2px solid #d94f46 !important; border-radius: 5px; background: transparent; color:#d94f46; font-size:14px; padding: 5px 10px; transition: all 0.3s}
.lbrown button:hover {background: #d94f46; color:#fff}
.btn-primary-outline {border:2px solid #2a9bdb !important; background: transparent; color: #2a9bdb !important; font-weight: 500 !important; padding: 10px 20px !important; transition: all 0.3s;}
.btn-primary-outline:hover, .btn-primary-outline:focus {background: #2a9bdb !important; color: #fff !important;}
.plain-btn {background: transparent; border: 0; padding: 0 !important; font-weight: 700;}
.text-blue, .text-blue svg {color: #37a1dd !important; fill: #37a1dd;}
.text-gray {color: #9a9a9a;}
.gray-border {border-color:#ededed !important}
#root .text-warning {color:#dc5050 !important}
.text-pink {color:#ee3197 !important}
.warning-icon {position: relative;}
.warning-icon::before {content:''; background: url('/public/assets/imgs/warning.svg') no-repeat; background-size: contain; position: absolute; left: -26px; top:4px; width: 20px; height: 20px; fill: #ee3197;}
button[data-direction] {width: 25px; height: 25px; display: block; cursor: pointer; border: none; position: absolute; background: url('./right-arrow.svg') no-repeat center; opacity: 0.3; margin-top: -40px;}
button[data-direction='left'] { /* Left button */   
    right: 90px;
    transform: rotateZ(180deg);
}
button[data-direction='right'] { /* Right button */   
    right: 50px;
}

.btn-plain, .btn-plain:focus, .btn-plain:visited {background:transparent; border: 1px solid #ddd; border-radius: 3px;  outline: 0;}
.prev, .prev:focus {background: transparent; border: 0; margin-right: 20px; outline: none;}
.prev svg {fill: #2a9bdb;}
.info {border-top:1px solid #a3a3a3; padding-top: 20px;}
.info h4 {font-size: 1.1rem !important; font-weight: 700;}
.round-border {border-radius: 100% !important;}

.chart div {
    position:relative;
    margin:80px;
    width:220px; height:220px;
}
.chart canvas {
    display: block;
    position:absolute;
    top:0;
    left:0;
}
.chart span {
    color:#555;
    display:block;
    line-height:220px;
    text-align:center;
    width:220px;
    font-family:sans-serif;
    font-size:40px;
    font-weight:100;
    margin-left:5px;
}

.chart input {width: 200px;}
.logoutbtn {cursor: pointer;}
.noborder-table table {width: 100%;}
.noborder-table table thead th {font-weight: 700; color: #9a9a9a; font-size: 14px;}
.noborder-table table tbody tr td:nth-child(1) {width: 10%; min-width: 40px;}
.noborder-table table tbody tr td:nth-child(2) {width: 60%;}
.noborder-table table tbody tr td:nth-child(3) {width: 10%; min-width: 70px;}
.noborder-table table tbody tr td:nth-child(4) {width: 10%; min-width: 60px;}
.noborder-table table tbody tr td:nth-child(5) {width: 10%; min-width: 60px;}


header {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    transition: all 0.3s;
}

header nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

header nav li {
    list-style: none;
    padding: 5px 10px;
}

header nav li a {
    text-decoration: none;
    color: #9a9a9a;
    font-weight: 400;
    font-size: 1.2rem;
    transition: all 0.3s;
    position: relative;
}
header nav li a::before {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 0;
    height: 4px;
    transition: all 0.3s;
    background-color:#023e62 ;
}


header nav li .active {
    text-decoration: none;
    color: #023e62;    
    font-weight: 700;
}
header nav li .active::before{
    width: 100%;
} 

#navbarCollapse ul {
    margin: 0; padding: 0;
}
#navbarCollapse ul li {list-style: none; display: inline-block;}
header .navbar {display: flex; justify-content: space-between; align-items: center; width: 100%;}
.logoutbtn {background-color: #fcd6ea; border-radius: 5px; padding: 10px; display: flex; align-items: center; justify-content: center;}
.circleName span {width: 40px; height: 40px; display: flex; border-radius: 50px; background-color: #023e62; color:#fff !important; font-weight: 400; justify-content: center; align-items: center; font-size: 1.3rem;}
.circleName span a {color:#fff;}
.circleName .active {color:#fff !important; text-decoration: none !important;}
.circleName .active::before {content: none;}
.green span {background:#60cf6b !important}
.pink span {background: #ef77dc !important;}
.brown span {background: #e59b65 !important;}
.rubyred span {background: #dc5050 !important;}
.purple span {background: #6b56c1 !important;}
.task {display: flex; justify-content: flex-start; align-items: flex-start; margin-top: 30px !important;}
.task div {padding-left: 15px;}
.grd1::before {content: ''; width: 5px; height: 100%; position: absolute; left: 0; top: 0; background: rgb(136,211,213);
    background: linear-gradient(180deg, rgba(136,211,213,1) 0%, rgba(72,107,225,1) 69%, rgba(72,107,225,1) 100%); border-top-left-radius: 5px; border-bottom-left-radius: 5px;}
.grd2::before {content: ''; width: 5px; height: 100%; position: absolute; left: 0; top: 0; background: rgb(250,105,241);
    background: linear-gradient(180deg, rgba(250,105,241,1) 0%, rgba(228,83,94,1) 69%, rgba(228,83,94,1) 100%); border-top-left-radius: 5px; border-bottom-left-radius: 5px;}
.calendarContainer {width: 100%; min-width: 300px; border:0 !important}
.calendarContainer .rc {width: 100% !important;}
.calendarContainer header {box-shadow: none; }
.max-width290 {max-width: 290px;}
.inlineList {margin: 0; padding: 0; text-align: center;}
.inlineList li {list-style: none; display: inline-block !important; margin:0 5px; box-shadow: none !important; background-color: transparent !important;}



.rc_root, .rc_shortcut_cal_root {
    --rc-color-transparent: transparent;
    --rc-color-text-dark: #353535;
    --rc-color-text-inactive: #c9c9ca;
    --rc-color-text-light: #fff;
    --rc-color-bg-light: #fff;
    --rc-color-border: #f3f3f3;
    --rc-color-text-hover: #f8f9fa;
    --rc-hsl-primary-hue: 208deg;
    --rc-hsl-primary: #023e62;
    --rc-hsl-primary-saturation: 77%;
    --rc-hsl-primary-light: 47%;
    --rc-hsl-accent-hue: 0deg;
    --rc-hsl-accent-saturation: 77%;
    --rc-hsl-accent-light: 47%;
    --rc-color-primary: #023e62;
    --rc-color-primary-light: hsla(var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light)/40%);
    --rc-color-primary-lighter: hsla(var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light)/8%);
    --rc-color-accent: hsl(var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light));
    --rc-color-accent-light: hsla(var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light)/40%);
    --rc-color-accent-lighter: hsla(var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light)/8%);
    --rc-color-border-root: var(--rc-color-border);
    --rc-color-bg-text-hover-header-button: var(--rc-color-text-hover);
    --rc-color-text-today: var(--rc-color-primary);
    --rc-color-border-weekdays: var(--rc-color-border);
    --rc-color-text-weekday-label: var(--rc-color-text-inactive);
    --rc-color-text-weekend-label: var(--rc-color-accent-light);
    --rc-color-text-dom-inactive: var(--rc-color-text-inactive);
    --rc-color-text-dom-active: var(--rc-color-text-dark);
    --rc-color-text-dom-weekend-active: var(--rc-color-accent);
    --rc-color-text-dom-weekend-inactive: var(--rc-color-accent-light);
    --rc-color-bg-dom-in-range-active: var(--rc-color-primary-lighter);
    --rc-color-bg-dom-in-range-inactive: var(--rc-color-primary-lighter);
    --rc-color-bg-dom-in-range-weekend-active: var(--rc-color-accent-lighter);
    --rc-color-bg-dom-range-ep-active: var(--rc-color-primary);
    --rc-color-bg-dom-range-ep-inactive: var(--rc-color-primary-lighter);
    --rc-color-bg-dom-range-ep-weekend-active: var(--rc-color-accent);
    --rc-color-bg-dom-range-ep-weekend-inactive: var(--rc-color-accent-lighter);
    --rc-color-text-dom-range-ep-weekend-inactive: var(--rc-color-accent-light);
    --rc-color-text-dom-range-ep-active: var(--rc-color-text-light);
    --rc-color-text-dom-range-ep-inactive: var(--rc-color-primary-light);
    --rc-color-bg-dom-selected: transparent;
    --rc-color-bg-dom-weekend-selected: var(--rc-color-accent);
    --rc-color-text-dom-selected: var(--rc-color-primary);
    --rc-color-text-dom-weekend-selected: var(--rc-color-text-light);
    --rc-color-text-disabled: var(--rc-color-text-inactive);
    --rc-color-text-disabled-weekend: var(--rc-color-accent-light);
    --rc-color-bg-disabled: var(--rc-color-bg-light);
    --rc-color-bg-disabled-cross: var(--rc-color-text-inactive);
    --rc-color-bg-disabled-weekend-cross: var(--rc-color-accent-light);
}

.historyContainer {padding-left: 40px; padding-right: 25px; border-left: 1px solid #ddd; width: 100%; max-height: 400px; overflow-y: auto; min-height: 340px;}
.historyContainer ul {margin: 0; padding: 0;}
.historyContainer ul  li {list-style: none; padding: 10px; border-radius: 5px; margin-bottom: 20px; background-color: #eaf5fb !important; color: #42a6df !important;}
.historyContainer ul  li .outlinebtn {border:1px solid #42a6df; padding: 5px 10px; border-radius: 5px; font-size: 14px;}
.historyContainer ul  li h4 {margin-bottom: 0; font-weight: 700;}
.historyContainer ul  li svg {margin-right: 10px;}

.historyContainer ul li:nth-child(2) {background-color: #fdeaf5 !important; color: #ee3197 !important;}
.historyContainer ul li:nth-child(2) .outlinebtn {border-color: #ee3197 !important; color: #ee3197 !important;}

.historyContainer ul li:nth-child(3) {background-color: #fbeded  !important; color: #d94f46 !important;}
.historyContainer ul li:nth-child(3) .outlinebtn {border-color: #d94f46 !important; color: #d94f46 !important;}

.historyContainer ul li:nth-child(4) {background-color: #eaf5fb !important; color: #42a6df !important;}
.historyContainer ul li:nth-child(4) .outlinebtn {border-color: #42a6df !important; color: #42a6df !important;}

.historyContainer ul li:nth-child(5) {background-color: #fdeaf5  !important; color: #ee3197 !important;}
.historyContainer ul li:nth-child(5) .outlinebtn {border-color: #ee3197 !important; color: #ee3197 !important;}

.historyContainer ul li:nth-child(6) {background-color: #fbeded  !important; color: #d94f46 !important;}
.historyContainer ul li:nth-child(6) .outlinebtn {border-color: #d94f46 !important; color: #d94f46 !important;}

.historyContainer ul li:nth-child(7) {background-color: #eaf5fb !important; color: #42a6df !important;}
.historyContainer ul li:nth-child(7) .outlinebtn {border-color: #42a6df !important; color: #42a6df !important;}

.historyContainer ul li:nth-child(8) {background-color: #fdeaf5  !important; color: #ee3197 !important;}
.historyContainer ul li:nth-child(8) .outlinebtn {border-color: #ee3197 !important; color: #ee3197 !important;}

.historyContainer ul li:nth-child(9) {background-color: #fbeded  !important; color: #d94f46 !important;}
.historyContainer ul li:nth-child(9) .outlinebtn {border-color: #d94f46 !important; color: #d94f46 !important;}

.historyContainer ul li:nth-child(10) {background-color: #eaf5fb !important; color: #42a6df !important;}
.historyContainer ul li:nth-child(10) .outlinebtn {border-color: #42a6df !important; color: #42a6df !important;}

.historyContainer ul li:nth-child(11) {background-color: #fdeaf5  !important; color: #ee3197 !important;}
.historyContainer ul li:nth-child(11) .outlinebtn {border-color: #ee3197 !important; color: #ee3197 !important;}

.historyContainer ul li:nth-child(12) {background-color: #fbeded  !important; color: #d94f46 !important;}
.historyContainer ul li:nth-child(12) .outlinebtn {border-color: #d94f46 !important; color: #d94f46 !important;}

.historyContainer::-webkit-scrollbar, .recentActivity::-webkit-scrollbar, .slim-scroll::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}
   
.historyContainer::-webkit-scrollbar-track, .recentActivity::-webkit-scrollbar-track, .slim-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #d4ebf8
}

.composeMessage {border:1px solid #ddd; padding: 15px;}
   
.historyContainer::-webkit-scrollbar-thumb, .recentActivity::-webkit-scrollbar-thumb, .slim-scroll::-webkit-scrollbar-thumb {
    background-color: #2a9bdb;
    outline: 1px solid #2a9bdb;
    border-radius: 100px;
}

.recentActivity {margin: 0; padding: 0; max-height: 400px; padding-right: 15px; overflow: auto;}
.recentActivity li {list-style: none; display: block; position: relative; padding-left: 80px; margin-bottom: 20px; color:#b4b3b3}
.recentActivity li h4, .recentActivity li h4 span, .recentActivity li strong {font-size: 0.9rem;}
.recentActivity li p {margin-bottom: 5px; font-size: 0.9rem; text-overflow: ellipsis; line-clamp: 1; -webkit-line-clamp: 1;}
.recentActivity li strong {color:#000; font-weight: 500;}
.recentActivity li:last-child::before {content: ''; display: none;}
.recentActivity li:before {content: ''; width: 1px; height: 100%; background-color: #d4d3d3; position: absolute; top: 50%; left: 30px;}
.recentActivity li:after {content: ''; width: 60px; height: 60px; position: absolute; left: 0; top: 0; background-color: #fff;}
.recentActivity li span {font-weight: 500;}
.project::after {background: #fff url('/public/assets/imgs/project.svg') no-repeat 2px;}
.project span {color: #2a9bdb !important;}
.status::after {background: #fff url('/public/assets/imgs/status.svg') no-repeat 2px;}
.status span {color: #f88d6c !important;}
.bill::after {background: #fff url('/public/assets/imgs/bills.svg') no-repeat 2px;}
.bill span {color:#60cf6b !important}
.msg::after {background: #fff url('/public/assets/imgs/msg.svg') no-repeat 2px;}
.msg span {color: #4f2bdc !important;}
.no-dues {position: relative; padding-left: 40px; color: #66ac49 !important;}
.no-dues::before{content: ''; width: 30px; height: 30px; background:url('/public/assets/imgs/smiley.svg') no-repeat left top; position: absolute; left: 0; top: 0; background-size: contain;}
.paid-icon {position: relative; padding-left: 4px; color: #66ac49 !important;}
.paid-icon::before{content: ''; width: 16px; height: 16px; background:url('/public/assets/imgs/check.svg') no-repeat left top; position: absolute; left: -21px; top: 6px; background-size: contain;}
/* .recentActivity li.project {} */


.contactForm {background-color: #eaf5fb; border-radius: 5px; padding: 50px 20px; display: flex; justify-content: center;align-items: center; flex-direction: column;}
.contactForm h4 {font-weight: 500; font-size: 1.3rem !important; color: #2a9bdb;}
.contactForm button {padding: 15px 35px; background-color: #023e62; color:#fff; box-shadow: 1px 2px 15px 3px rgb(0 0 0 / 30%); border: 2px solid #023e62; font-size: 1.3rem; font-weight: 500; transition: all 0.3s;}
.contactForm button:hover, .contactForm button:focus {background-color: transparent; color: #023e62; border-color: #023e62;}
.btn-manage {background-color: #e5f3fb !important; color:#36a0dd !important; font-weight: 500 !important; padding: 10px 30px !important; transition: all 0.3s;}
.btn-manage svg {fill:#36a0dd !important;}
.btn-manage:hover, .btn-manage:focus {box-shadow: 1px 2px 10px 1px rgb(0 0 0 / 20%);}
.donutchart-legend-item-label {font-size: 12px; transform: translate(-37px, -7px)}
.donutchart-legend-item rect {font-size: 12px; transform: translate(-37px, -7px)}
.donutchart-legend-item {overflow: visible;}
.donutchart-legend {margin-left: 20px;}
.react-calendar__tile--active { background: #006edc; color: white; border-radius: 100%;}

.filelist {width: 100%;}
.filelist li {list-style: none; display: inline-block; width: 300px; border:1px solid #ddd; margin:10px; position: relative; padding: 80px 10px 10px 10px; border-radius: 5px; box-shadow: 0px 0px 8px 1px rgba(0, 0, 0,0.1);}
.filelist li span {white-space: nowrap; text-overflow: ellipsis; max-width: 270px; overflow: hidden;}
.filelist li .fileExtension {position: absolute; top: -1px; font-size: 50px; font-weight: 700; color: rgba(255,255,255,0.15); text-transform: uppercase;}
.filelist li::before {content: ''; width: 100%; height: 70px; background: #dc5050; left: 0; top: 0; position: absolute; border-top-left-radius: 5px; border-top-right-radius: 5px;}
.filelist li:nth-child(1)::before {background: #dc5050;}
.filelist li:nth-child(2)::before {background: #2a9bdb;}
.filelist li:nth-child(3)::before {background: #66ac49;}
.filelist li:nth-child(4)::before {background: #dc5050;}
.filelist li:nth-child(5)::before {background: #2a9bdb;}
.filelist li:nth-child(6)::before {background: #66ac49;}
.filelist li:nth-child(7)::before {background: #dc5050;}
.filelist li:nth-child(8)::before {background: #2a9bdb;}
.filelist li:nth-child(9)::before {background: #66ac49;}
.list-view-circle {margin: 0; padding: 0; max-height: 400px; overflow: auto;}
.list-view-circle li {list-style: none; display: flex; justify-content: space-between; padding: 20px 0px; align-items: center; border-bottom: 1px solid #ddd;}
.list-view-circle li:last-child {border-bottom: 0;}
.list-view-circle li span {color:#958f8f}
.list-view-circle li div span {width: 50px; height: 50px; display: flex; border-radius: 50px; background-color: #023e62; color:#fff; font-weight: 400; justify-content: center; align-items: center; font-size: 1.3rem; margin-right: 10px;}
.list-view-circle li div h4 {margin-bottom: 0 !important; font-weight: 500; font-size: 1.030rem !important;}
.list-view-circle li div h4 small {display: block; color:#958f8f}
.list-view-circle li:nth-child(1) div span {background-color: #4f2bdc !important; color:#fff !important}
.list-view-circle li:nth-child(2) div span {background-color: #d94f46 !important; color:#fff !important}
.list-view-circle li:nth-child(3) div span {background-color: #e59b65 !important; color:#fff !important}
.list-view-circle li:nth-child(4) div span {background-color: #2a94f4 !important; color:#fff !important}

.paymentdescrlist {margin: 0; padding: 0; display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: center; border-left: 1px solid #ddd; padding-left: 20px;}
.paymentdescrlist li {list-style: none; width:100%; max-width: 225px; margin: 20px 0px; position: relative; background-color: #fff; padding: 0px 0px 0px 70px;}
.paymentdescrlist li:after {content: ''; width: 60px; height: 60px; position: absolute; left: 0; top: 0; }
.paymentdescrlist li.grosbill::after {background: #fff url('/public/assets/imgs/grosbill.svg') no-repeat 2px;}
.paymentdescrlist li.newbill::after {background: #fff url('/public/assets/imgs/netbill.svg') no-repeat 2px;}
.paymentdescrlist li.lastpayment::after {background: #fff url('/public/assets/imgs/lastPay.svg') no-repeat 2px;}
.paymentdescrlist li.taxAmt::after {background: #fff url('/public/assets/imgs/taxAmt.svg') no-repeat 2px;}
.paymentdescrlist li.amtRec::after {background: #fff url('/public/assets/imgs/amtRec.svg') no-repeat 2px;}
.paymentdescrlist li.lastPaymentDate::after {background: #fff url('/public/assets/imgs/lastPayment.svg') no-repeat 2px;}
.searchfilter .col {position: relative;}
.searchfilter input {max-width: 385px; padding: 10px 10px 10px 50px; border: 2px solid #ddd; border-radius: 10px;}
.searchfilter .searchicon::before {content: ''; background: url('/public/assets/imgs/search.svg') no-repeat top left; width: 20px; height: 20px; background-size: contain; position: absolute; left: 15px; top: 14px; opacity: 0.3;}
.searchfilter .searchicon2::before {content: ''; background: url('/public/assets/imgs/search.svg') no-repeat top left; width: 20px; height: 20px; background-size: contain; position: absolute; left: 30px; top: 14px; opacity: 0.3;}
.searchfilter ul {display: flex; margin: 0; padding: 0; background: #eaeaea; border-radius: 5px; padding: 5px;}
.searchfilter ul li {list-style: none; padding: 5px 10px; color: #9a9a9a; cursor: pointer;}
.searchfilter ul li svg {fill: #9a9a9a;}
.searchfilter ul li.active {background: #fff; color: #2a9bdb; border-radius: 5px; cursor: default;}
.searchfilter ul li.active svg {fill: #2a9bdb;}
.searchfilter .filter {background:#d4ebf8; padding: 10px; border-radius: 5px; margin-right: 10px; cursor: pointer; display: inline-block; width: 100%;}

#bills-list ul {display: flex; margin: 0; padding: 0; justify-content: space-around; flex-wrap: wrap; }
#bills-list ul li {list-style: none;  background: #fff; padding: 50px; position: relative; width: 100%; max-width: 400px; border-left: 1px solid #d3d3d3; border-right: 1px solid #d3d3d3; margin: 20px 10px; flex-wrap: wrap; display: flex; flex-direction: column;}

#bills-list ul li::before {content: ''; position: absolute; left: -0.004%; top: 0; width: 100.25%; height: 14px; background: url('/public/assets/imgs/top_bg_curve1.png') repeat-x;}
#bills-list ul li::after {content: ''; position: absolute; left: -0.003%; bottom: 0; width: 100.25%; height: 14px; background: url('/public/assets/imgs/top_bg_curve.png') repeat-x 9px 0px;}


#bills-list ul.listView {display: flex; margin: 0; padding: 0; justify-content: space-around; flex-direction: column;}
#bills-list ul.listView li {list-style: none; background: #fff; padding: 20px; position: relative; width: 100%; max-width: 100%; border: 0px; border-radius: 5px; margin: 20px 10px; display: grid; flex-direction: column; grid-auto-flow: column; justify-content: space-between; box-shadow: 0px 0px 7px 2px rgba(0, 0, 0,0.1); position: relative; align-items: center; grid-template-columns: 237px [col-start] 202px [col-start] 210px [col-start] 195px [col-start] 190px [col-start] 215px [col-start] 157px [col-start];}
#bills-list ul.listView li hr {display: none;}
#bills-list ul.listView li .row {width: auto !important;}
#bills-list ul.listView li .mt20, #bills-list ul.listView li .mt30 {margin-top: 0px !important;}
#bills-list ul.listView li h2 {font-size: 1.5rem;}
#bills-list ul.listView li span.text-blue {margin-left: 20px;}
#bills-list ul.listView li .col small {width: 100%;}
#bills-list ul.listView li div:first-child {display: flex; flex-wrap: wrap; min-width: 200px;}
#bills-list ul.listView li .payment-status {position: absolute; top: 56px; left: 44px; font-size: 18px !important;}
#bills-list ul.listView li .warning-icon::before {left: -24px; top: 3px; width: 18px; height: 18px;}
#bills-list ul.listView li::before, #bills-list ul.listView li::after {display: none;}


#job-list ul.listView {display: flex; margin: 0; padding: 0; justify-content: space-around; flex-direction: column;}
#job-list ul.listView li {list-style: none; background: #fff; padding: 20px; position: relative; width: 100%; max-width: 100%; border: 0px; border-radius: 5px; margin: 20px 10px; display: grid; flex-direction: column; grid-auto-flow: column; box-shadow: 0px 0px 7px 2px rgba(0, 0, 0,0.1); position: relative; align-items: center;}
#job-list ul.listView li hr {display: none;}
#job-list ul.listView li .row {width: auto !important;}
#job-list ul.listView li .mt20, #bills-list ul.listView li .mt30 {margin-top: 0px !important;}
#job-list ul.listView li h2 {font-size: 1.5rem;}
#job-list ul.listView li h3 {font-size: 1.2rem !important; margin-bottom: 0;}
#job-list ul.listView li span.text-blue {margin-left: 20px;}
#job-list ul.listView li .col small {width: 100%;}
#job-list ul.listView li .payment-status {position: absolute; top: 56px; left: 44px; font-size: 18px !important;}
#job-list ul.listView li .warning-icon::before {left: -24px; top: 3px; width: 18px; height: 18px;}

#job-list ul.listView li::before, #bills-list ul.listView li::after {content: ''; display: none;}

#job-list ul {display: flex; margin: 0; padding: 0; flex-wrap: wrap; align-items: center; justify-content: center; }
#job-list ul li {list-style: none; margin: 12px; background: #fff; padding: 25px; position: relative; width: 100%; max-width: 400px; flex-wrap: wrap; display: flex; flex-direction: column; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,0.1); border-radius: 5px;}

.peoples h3 {font-size: 1.2rem !important;}
.stagewiselist {margin: 0; padding: 0; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;}
.stagewiselist li {list-style: none; width: 50%; padding: 0px 15px 0px 0px;}
.stagewiselist li:nth-child(1) .progress-bar, #projectsTable tbody tr:nth-child(1) .progress-bar {background-color: #ef476f;}
.stagewiselist li:nth-child(2) .progress-bar, #projectsTable tbody tr:nth-child(2) .progress-bar {background-color: #118ab2;}
.stagewiselist li:nth-child(3) .progress-bar, #projectsTable tbody tr:nth-child(3) .progress-bar {background-color: #aacc01;}
.stagewiselist li:nth-child(4) .progress-bar, #projectsTable tbody tr:nth-child(4) .progress-bar {background-color: #ffd166;}
.stagewiselist li:nth-child(5) .progress-bar, #projectsTable tbody tr:nth-child(5) .progress-bar {background-color: #ed8261;}
.stagewiselist li:nth-child(6) .progress-bar, #projectsTable tbody tr:nth-child(6) .progress-bar {background-color: #9a9a9a;}
.stagewiselist li:nth-child(7) .progress-bar, #projectsTable tbody tr:nth-child(7) .progress-bar {background-color: #b9d6fc;}
.stagewiselist li:nth-child(8) .progress-bar, #projectsTable tbody tr:nth-child(8) .progress-bar {background-color: #d6a0fa;}
.stagewiselist li:nth-child(9) .progress-bar, #projectsTable tbody tr:nth-child(9) .progress-bar {background-color: #fffeb0;}


.peoples .thumb0 span {background:#60cf6b !important}
.peoples .thumb1 span {background:#ef77dc !important}
.peoples .thumb2 span {background:#e59b65 !important}
.peoples .thumb3 span {background:#dc5050 !important}
.peoples .thumb4 span {background:#6b56c1 !important}
.peoples .thumb5 span {background:#b5c751 !important}
.peoples .thumb6 span {background:#54b3b6 !important}
.peoples .thumb7 span {background:#d353a8 !important}

.projmembers li {margin: 10px 0px; padding: 10px; border: 1px solid #ddd; border-radius: 5px;}
.projmembers li h4 {white-space: nowrap; text-overflow: ellipsis; max-width: 300px; overflow: hidden; margin-bottom: 0;}
.projmembers li:nth-child(1) .thumb {background:#60cf6b !important}
.projmembers li:nth-child(2) .thumb {background:#ef77dc !important}
.projmembers li:nth-child(3) .thumb {background:#e59b65 !important}
.projmembers li:nth-child(4) .thumb {background:#dc5050 !important}
.projmembers li:nth-child(5) .thumb {background:#6b56c1 !important}
.projmembers li:nth-child(6) .thumb {background:#b5c751 !important}
.projmembers li:nth-child(7) .thumb {background:#54b3b6 !important}
.projmembers li:nth-child(8) .thumb {background:#d353a8 !important}

#job-list h4 {font-size: 1.5rem; font-weight: 700;}
#job-list h4 span {font-weight: 500;}
#projectsTable {border-collapse: unset;}
#projectsTable thead, th, td {border:0!important }
#projectsTable thead th {font-weight: 500; color: #b4b3b3;}
#projectsTable tbody h4 {font-weight: 700; color:#023e62; margin-bottom: 0;}
#projectsTable tbody .progress {height: 0.55rem;}
.border-dotted {border:2px dashed #ddd; margin: 5px; padding: 10px;}
.border-dotted h4 span {width: 100%; display: block;}
#root .btn-group .btn-primary {background-color: #efefef !important; border-color:#efefef; color: #9a9a9a; padding: 5px 20px; font-size: 14px; font-weight: 700;}
#root .btn-group {padding: 8px; background-color:#efefef; border-radius: 15px;}
#root .btn-group .btn-primary.active {background-color: #ffffff !important; border-color:#efefef; box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);  color: #000; font-weight: 500; padding: 15px 25px; border-radius: 10px;}
.gridlisticon {padding: 10px; background-color: #eaeaea; border-radius: 5px; margin-bottom: 0;}
.gridlisticon li {list-style: none; display: inline-block; padding: 5px; cursor: pointer; transition: all 0.3s;}
.gridlisticon li.active {background-color: #fff; border-radius: 5px; color: #2a9bdb !important; cursor: default;}
.gridlisticon li.active svg {fill: #2a9bdb;}

#root .page-link {
    color: #9a9a9a;   
    background-color: #fff;
    border: 0 !important;    
    border-radius: 100px;
}
#root .pagination li.active .page-link {
    background-color: #2a9bdb;
    color: #fff;    
}
.actbtn {font-weight: 500; color: #023e62 !important;}
.disabled {display: none;}
.success-box {background-color: #e0eedb; padding: 5px 15px !important; border-radius: 5px; font-weight: 500 !important; white-space: nowrap; font-size: 14px !important;}
.warning-box {background-color: #fee8e2; padding: 5px 15px !important; border-radius: 5px; font-weight: 500 !important; color: #f88d6c; white-space: nowrap; font-size: 14px !important;}
.querysent2-box {background-color: #dcd5f8; padding: 5px 15px !important; border-radius: 5px; font-weight: 500 !important; color: #4f2bdc; white-space: nowrap; font-size: 14px !important;}
.sentforAudit-box {background-color: #ccd8e0; padding: 5px 15px !important; border-radius: 5px; font-weight: 500 !important; color: #023e62; white-space: nowrap; font-size: 14px !important;}

.success-text {font-weight: 500 !important;}
.warning-text {font-weight: 500 !important; color: #f88d6c;}
.querysent2-text {font-weight: 500 !important; color: #4f2bdc;}
.sentforAudit-text {font-weight: 500 !important; color: #023e62;}
.modal {position: fixed; top: 98px; left: 1%; z-index: 1055; display: none; width: 98%; height: 100%; overflow-x: hidden; overflow-y: auto; outline: 0;}
/* #commonModal {top:115px} */
.modal-fullscreen {width: 98vw;max-width: none;height: 100%;margin: 0;}

.border-blue {border:1px solid #023e62; border-radius: 5px; padding: 20px;}
.modal-body {padding: 30px !important;}
.noleftRight {margin:0 !important; box-sizing: border-box;}
.fileuploadBtn {position: relative;}
.fileuploadBtn input {position: absolute; z-index: 2; opacity: 0; width: 100%; height: 100%;}
.fileuploadBtn button {border:2px solid #3db0f0; background-color: #fff; color: #3db0f0;}
input, textarea, select {border: 1px solid #023e62 !important; transition: all 0.3s;}
.form-label {color:#023e62 !important; font-weight: 600 !important;}
.fileAttachedList {margin: 0; padding: 0;}
.fileAttachedList li {list-style: none; padding: 10px 15px; background-color: #eaeaea; font-weight: 600; color: #000; margin-bottom: 10px; border-radius: 5px;}
.dark-bg {background-color: #37a1dd; color: #fff;}

#usersTable li {display: grid; grid-template-columns: 1fr 2fr 1fr 1fr 1fr;  grid-column-gap: 1px; align-items: center; background-color: #fff; box-shadow: 0 0 10px 2px rgba(0,0,0,0.1); padding: 15px; border-radius: 5px; margin: 10px 0px 20px 0px;}
#usersTable li ul li {margin: 0; box-shadow: none;}
.card-title {background-color: #f3f3f3; padding: 15px; color: #4b4949; border-top-left-radius: 5px; border-top-right-radius: 5px; color: #023e62;}
.card-body {padding: 50px !important;}


footer {margin-top: 50px; border-top: 1px solid #d3d3d3; padding-top: 40px; padding-bottom:  40px; }
footer ul {margin: 0; padding: 0;}
footer ul li {display: inline-block; position: relative; padding: 0px 20px; color: #909090; font-size: 1rem; }
footer ul li:first-child {padding-left: 0;}
footer ul li:first-child::before {content: ''; display: none;}
footer ul li::before {content: ''; position: absolute; left: -7px; top: 39%; width: 10px; height: 10px; border-radius: 10px; background-color: #909090;}
.navbar-collapse {flex-grow: 0 !important;}


#mailbox nav {box-shadow: 3px 2px 10px 3px rgba(0,0,0,0.1); border-radius: 5px; padding: 10px;}
#mailbox nav li {list-style: none; border-bottom: 1px solid #ddd; padding: 10px; cursor: pointer;}
#mailbox nav li.active {background: #dcecf5;}
#mailbox nav li:last-child {border-bottom: 0px;}
#mailbox nav li h3 {color: #023e62; font-size: 1.2rem !important;}
#mailbox nav li p {color: #9a9a9a;}
#mailbox nav li small {color: #9a9a9a ;}
.btn-primary-outline:hover svg, .btn-primary-outline:focus svg {fill: #ffffff;}
.attachlist {margin: 0; padding: 0;  display: flex; justify-content: flex-start; align-items: center;}
.attachlist li {list-style: none; margin: 0px 10px; background: #eaeaea; padding: 10px; border-radius: 5px; display: flex; align-items: center;}
.attachlist li h4 {margin-bottom: 0;}
.attachlist li:first-child {margin-left: 0;}
.attachlist li button {background: none; border: 0; outline: none;}
#mailbox .btn-primary-outline {margin:5px 10px}
.filterOptions {position: absolute; background: #fff; width: 200px; right: 10px; top: 32px; box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%); border-radius: 5px; padding: 10px; z-index: 2; font-size: 14px;}
.filterOptions li {display: flex; justify-content: flex-start !important; align-items: center !important; flex-direction: row !important;}
.filterOptions label {font-weight: 700;}
.filterOptions .form-select {font-size: 0.9rem !important;}
.filterOptions h4 {font-weight: 700 !important; color: #023e62;}
.filterOptions .btn-group {padding: 0 !important;}
.filterOptions .btn-group .btn-primary {padding: 3px 5px !important;}
.filterOptions input {padding: 10px;}
#root .filterOptions .btn-group .btn-primary.active {background-color: #3db0f0 !important; color:#fff !important; border-radius: 5px !important;}
#root .filterOptions li {list-style: none; padding: 3px 5px !important; box-shadow: none; color: #2a9bdb; font-weight: 700; margin: 5px 5px; cursor: pointer;}
.projectHeader {background-color: #f4fafd; padding: 20px 10px;}
.comments {margin: 0; padding: 0;}
.comments li {list-style: none; margin-left: 20px; margin-top: 20px;}
.comments li h4 {margin-bottom: 0; font-weight: 700; color: #2a9bdb;}
.text-light-blue {color: #2a9bdb !important;}
.mypic {width: 185px; height: 185px; border: 2px solid #ddd; border-radius: 100%; position: relative; display: flex; align-items: center; justify-content: center;}
.mypic .fileuploadBtn {position: absolute; right: -31px; top: 110px; cursor: pointer;}
.mypic .fileuploadBtn button {border-radius: 100px; background: #3db0f0; color: #fff; height: 50px; width: 50px;  cursor: pointer;}
.mypic .fileuploadBtn input {height: 50px; width: 50px;  cursor: pointer;}
.mypic span {display: inline-block; position: absolute; right: 0; top: 30%}
.profile-nav {display: flex; margin-bottom: -25px;}
.profile-nav li {list-style: none; padding: 20px 20px; color: #a0a0a0; font-weight: 500; cursor: pointer;}
.profile-nav li.active {color: #2a9bdb; font-weight: 700; border-bottom: 2px solid #2a9bdb;}
.font1Vew {font-size: 0.8vw; font-weight: 700;}

.o_day-picker .i_day-picker-header {background: #ffffff; color: #504f4f;}
.o_day-picker .i_day-picker-row > div {text-align: center; line-height: 40px; border: 0;}
.o_day-picker .i_day-picker-row [class*="-day"] {text-align: center;}
.o_day-picker .i_day-picker-row {border:0}
.highlightCalendar {color: #2a9bdb !important; font-weight: 700 !important;}
.highlightCalendar abbr {color: #2a9bdb !important; font-weight: 700;}
.attachfilelistCompose {margin: 0; padding: 0;}
.attachfilelistCompose li {list-style: none; background: #ddd; color: #353535; border-radius: 3px; width: 95%; max-width: 280px; display: flex; justify-content: space-between; align-items: center; padding: 5px 10px;}
.react-confirm-alert-body h1 {font-size: 1.2rem !important; border-bottom: 1px solid; padding: 0px 0px 5px 0px; margin-bottom: 20px;}
.react-confirm-alert-button-group > button {background: #01385c !important; text-transform: uppercase ;}
.inline-list li {box-shadow: none !important; flex-direction: unset !important;  width: 25px !important; padding: 0 !important;}

@media screen and (max-width:768px) {    
    #navbarCollapse ul li {display: block;}
    header nav li a {font-size: 16px;}
    header nav li a::before {display: none;}
    .padlr50 {padding-left: 15px !important; padding-right: 15px !important;}
    #usersTable li {display: grid; grid-template-columns: 1fr 1fr 1fr;  grid-column-gap: 1px; align-items: center;}
    #navbarCollapse ul {margin: 0; padding: 0;  display: flex;  align-items: center; justify-content: center;}
    .inline-list-bullet {flex-wrap: wrap;}
    .stagewiselist li {width: 100%;}
    .font1Vew {font-size: 1.4vw;}
    .block {margin-top: 10px; margin-left: 10px; margin-right: 0;}
    #job-list ul li {max-width: 335px;}
     /* #job-list ul.listView li {max-width: 335px;} */
    .hidden-md, .searchfilter .hidden-md {display: none !important;}
    #job-list h4 {font-size: 14px !important;}
    .paymentdescrlist li {width: 45%;}
    .text-center-md {text-align: center;}
    .paymentdescrlist li h3 {font-size: 14px !important;}
    #bills-list ul li {max-width: 340px;}
    #root .btn-group .btn-primary {font-size: 13px;}
    #bills-list ul.listView li {
        grid-template-columns: 169px [col-start] 69px [col-start] 74px [col-start] 75px [col-start] 70px [col-start] 83px [col-start] 97px [col-start];
        margin: 10px 5px;
    }
    #bills-list ul.listView li p {display: none;}
    #root #bills-list ul.listView li h2 {font-size: 16px !important;}
    #root #bills-list ul.listView li h3 {font-size: 14px !important;}
    .searchfilter {display: flex; }
    /*.navbar-collapse {flex-grow: 1 !important;}
     .navbar-expand-md{flex-wrap: wrap; align-items: flex-start;}
    .collapse:not(.show) {display: none !important;}
    .navbar-expand-md .navbar-toggler {display: inline-block !important;}   
    .navbar-expand-md .navbar-collapse {display: auto !important;}  */
}

@media screen and (min-width:1366px) {
    .padlr50 {padding-left: 35px !important; padding-right: 35px !important;}
    #bills-list ul.listView li {grid-template-columns: 216px [col-start] 177px [col-start] 177px [col-start] 151px [col-start] 125px [col-start] 146px [col-start] 111px [col-start];}
}
    

@media screen and (max-width:550px) {    
    #usersTable li {display: grid; grid-template-columns: 1fr;  grid-column-gap: 1px; align-items: center; text-align: center;}
    #usersTable .justify-content-start {justify-content: center!important;}
    #navbarCollapse ul {margin: 0; padding: 0;  display: block;  align-items: center; justify-content: center;}
    .mob-12 {width: 100% !important;}
    .col-xs-hidden {display: none;}
    .block {margin: 10px 0px;}
    /* .calendarContainer {margin-left: -33px;} */
    .historyContainer {padding-left: 0; padding-right:0; border-left:0}
    .stagewiselist li{width: 100%;}
    .font1Vew {font-size: 14px; font-weight: 700;}
    .mob-center {text-align: center !important;}
    .mob-hidden {display: none !important;}
    .mob-align-center {margin-left: auto !important; margin-right: auto !important;}
    .inline-list-bullet {flex-wrap: nowrap;}    
    .padlr50 {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .paymentdescrlist {border-left: 0;}
    .paymentdescrlist li {width: 100%;}
    .inline-btn {display: block;}
    .inline-btn a {width: 45%; display: inline-block; text-align: center;}
    #job-list ul.listView li {max-width: 335px;}
    #root .btn-group .btn-primary {font-size: 14px;}
    .profile-nav li {padding: 15px 5px; font-size: 14px;}
    .table-response {width: 100%; overflow-x: auto;}
    .col-hidden {display: none;}
    #bills-list ul.listView li {
        grid-template-columns: none;
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
    }
    .mob-hidden {display: none;}
}